<template>
  <section class="section has-background-danger">
    <div class="container has-text-centered">
      <h1 class="title  is-size-3 has-text-weight-light is-spaced">
        Load Error!
      </h1>
      <h2 class="subtitle  is-spaced">
        Missing template of type => "{{type}}"
      </h2>
      <p>pageData supplied is:</p>
    </div>
    <div>
      <pre class="is-font-monospaced">{{pageDataFieldsString}}</pre>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'LoadError',
    props: {
      pageData: Object,
      type: String
    },
    computed: {
      pageDataFieldsString() {
        return JSON.stringify(this.pageData.fields, null, 2);
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
